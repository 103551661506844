import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import {
  CURRENCY,
  PAYMENT_PROVIDER,
  TRANSIT_TRANSACTION_DIRECTION,
  TRANSIT_TRANSACTION_STATUS
} from 'components/constants';
import Loader from 'components/Loader';
import Button from 'uikit/Button/Button';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './TransitTransactionsFiltersBar.scss';

const TransitTransactionsFiltersBar = ({
  isLoading,
  isInitialized,
  periodDate,
  filters,
  handleSetPeriodDate,
  handleSetFilters,
  handleSearchText,
  handleExportPeriodTransitTransactionList,
  handleExportTransitTransactionList
}) => {
  const i18n = useContext(i18nContext);

  const transferProviderOptions = Object.keys(PAYMENT_PROVIDER).map((provider) => {
    return {
      key: PAYMENT_PROVIDER[provider].name,
      value: i18n.getMessage('paymentProvider.' + PAYMENT_PROVIDER[provider].name)
    };
  });

  const transitTransactionsStatusesOptions = Object.keys(TRANSIT_TRANSACTION_STATUS).map((status) => {
    return {
      key: TRANSIT_TRANSACTION_STATUS[status],
      value: i18n.getMessage('transitTransactionStatus.' + TRANSIT_TRANSACTION_STATUS[status])
    };
  });

  const currencyOptions = Object.keys(CURRENCY).map((status) => {
    return {
      key: CURRENCY[status],
      value: CURRENCY[status]
    };
  });

  const transitTransactionsDirectionOptions = Object.keys(TRANSIT_TRANSACTION_DIRECTION).map((status) => {
    return {
      key: TRANSIT_TRANSACTION_DIRECTION[status],
      value: i18n.getMessage('transitTransactionDirection.' + TRANSIT_TRANSACTION_DIRECTION[status])
    };
  });

  return (
    <div className={'transit-transactions-filters-wrapper'}>
      <div className={'transit-transactions-actions-wrapper'}>
        <div className={'transit-transactions-date-range-filters-wrapper'}>
          <div>
            <span>{i18n.getMessage('transitTransactions.filter.periodDate.label')}</span>
            <CustomDatePicker onChange={handleSetPeriodDate} name={'periodDate'} value={periodDate} />
          </div>
          <div>
            <span>{i18n.getMessage('transitTransactions.filter.from.label')}</span>
            <CustomDatePicker onChange={handleSetFilters} name={'from'} value={filters.from} />
          </div>
          <div>
            <span>{i18n.getMessage('transitTransactions.filter.to.label')}</span>
            <CustomDatePicker onChange={handleSetFilters} name={'to'} value={filters.to} />
          </div>
        </div>
        <div className={'export-transit-transactions-reports-actions-wrapper'}>
          <Button
            className={'transit-transactions-button'}
            size={'medium'}
            type={'primary'}
            onClick={handleExportPeriodTransitTransactionList}
            isDisabled={!isInitialized || isLoading}
          >
            {isLoading ? <Loader /> : i18n.getMessage('transitTransactions.button.downloadPeriodCsv')}
          </Button>
          <Button
            className={'transit-transactions-button'}
            size={'medium'}
            type={'primary'}
            onClick={handleExportTransitTransactionList}
            isDisabled={!isInitialized || isLoading}
          >
            {isLoading ? <Loader /> : i18n.getMessage('transitTransactions.button.downloadCsv')}
          </Button>
        </div>
      </div>

      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.transferProvider.label')}
        name={'transfer_providers'}
        value={filters.transfer_providers}
        options={transferProviderOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.transactionStatus.label')}
        name={'statuses'}
        value={filters.statuses}
        options={transitTransactionsStatusesOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.currency.label')}
        name={'currencies'}
        value={filters.currencies}
        options={currencyOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.direction.label')}
        name={'direction'}
        value={filters.direction}
        options={transitTransactionsDirectionOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <Input
        className={'filter-wrapper'}
        label={i18n.getMessage('transitTransactions.filter.search.label')}
        placeholder={i18n.getMessage('transitTransactions.filter.search.placeholder')}
        name={'search_text'}
        value={filters.search_text}
        onChange={handleSearchText}
        Icon={SearchIcon}
      />
    </div>
  );
};

TransitTransactionsFiltersBar.propTypes = {
  isLoading: PropTypes.bool,
  isInitialized: PropTypes.bool,
  periodDate: PropTypes.object,
  filters: PropTypes.object,
  handleSetPeriodDate: PropTypes.func,
  handleSetFilters: PropTypes.func,
  handleSearchText: PropTypes.func,
  handleExportPeriodTransitTransactionList: PropTypes.func,
  handleExportTransitTransactionList: PropTypes.func
};

export default TransitTransactionsFiltersBar;
