import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { formatDateTime, formatMoney } from 'services/utils';
import Button from 'uikit/Button/Button';
import './PopUpAccountDetailsScheme.scss';

const PopUpAccountDetailsScheme = ({ isLoading, accountDetails, handleClose, handleSetPrimary }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className={'scheme-wrapper scheme-wrapper-container'}>
      <div className={'scheme-wrapper-container-header'}>
        <h3>
          {i18n.getMessage('accountsTab.modal.accountDetails.title', { accountNumber: accountDetails.wallet_number })}
        </h3>
        <CloseIcon className={'scheme-wrapper-container-header-close-icon'} onClick={handleClose} />
      </div>
      <table className={'table-wrapper'}>
        <tbody className={'table-body'}>
          <tr className={'table-row scheme-wrapper-container-marked'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.available')}</td>
            <td className={'table-cell'}>
              {formatMoney(accountDetails.available, accountDetails.currency)} {accountDetails.currency}
            </td>
          </tr>
          <tr className={'table-row scheme-wrapper-container-marked'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.onHold')}</td>
            <td className={'table-cell'}>
              {formatMoney(accountDetails.on_hold, accountDetails.currency)} {accountDetails.currency}
            </td>
          </tr>
          <tr className={'table-row scheme-wrapper-container-marked'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.total')}</td>
            <td className={'table-cell'}>
              {formatMoney(accountDetails.total, accountDetails.currency)} {accountDetails.currency}
            </td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.iban')}</td>
            <td className={'table-cell'}>{accountDetails.iban || accountDetails.multi_iban}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.country')}</td>
            <td className={'table-cell'}>{accountDetails.bank_country}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.bankName')}</td>
            <td className={'table-cell'}>{accountDetails.bank_name}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.bankAddress')}</td>
            <td className={'table-cell'}>{accountDetails.bank_address}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.bic')}</td>
            <td className={'table-cell'}>{accountDetails.bic}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.paymentMethods')}</td>
            <td className={'table-cell'}>{accountDetails?.payment_methods?.join(', ')}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.createdAt')}</td>
            <td className={'table-cell'}>{formatDateTime(accountDetails.created_at)}</td>
          </tr>
          <tr className={'table-row'}>
            <td className={'table-cell'}>{i18n.getMessage('accountsTab.modal.accountDetails.primary')}</td>
            <td className={'table-cell'}>
              {accountDetails.is_primary_wallet ? i18n.getMessage('yes') : i18n.getMessage('No')}
            </td>
          </tr>
        </tbody>
      </table>
      <Button
        className={'scheme-wrapper-container-button'}
        type={'primary'}
        onClick={() => handleSetPrimary(accountDetails.wallet_number)}
        isDisabled={isLoading}
      >
        {i18n.getMessage('accountsTab.modal.accountDetails.setAsPrimary')}
      </Button>
    </div>
  );
};

PopUpAccountDetailsScheme.propTypes = {
  isLoading: PropTypes.bool,
  accountDetails: PropTypes.object,
  handleClose: PropTypes.func,
  handleSetPrimary: PropTypes.func
};

export default PopUpAccountDetailsScheme;
