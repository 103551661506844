import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CUSTOMER_PERMISSIONS } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import './RepresentativesTableContentRow.scss';

const permissionsArray = Object.values(CUSTOMER_PERMISSIONS);

export const RepresentativesTableContentRow = ({
  isLoading,
  representativeDetails,
  handleChangeRepresentative,
  handleRemoveRepresentative
}) => {
  const i18n = useContext(i18nContext);

  const getRepresentativeInfoBlock = (representativeBlockInfo, isCustomerId) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'representatives-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isCustomerId) {
      return (
        <NavLink
          className={'representatives-customer-id-text'}
          to={`${ROUTE_PATHS.ACTIVE_CUSTOMERS}/individual/${representativeBlockInfo}`}
        >
          {representativeBlockInfo}
        </NavLink>
      );
    } else {
      return <p>{representativeBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'representatives-table-cell'}>
        {getRepresentativeInfoBlock(
          `${representativeDetails.representative_account?.first_name} ${representativeDetails.representative_account?.last_name}`
        )}
      </td>
      <td className={'representatives-table-cell'}>
        {getRepresentativeInfoBlock(representativeDetails.representative_account?.account_number, true)}
      </td>
      <td className={'representatives-table-cell'}>
        {getRepresentativeInfoBlock(
          representativeDetails?.permissions
            ?.slice()
            .sort((a, b) => permissionsArray.indexOf(a) - permissionsArray.indexOf(b))
            .map((permission) => i18n.getMessage(`representatives.permission.${permission}`) + '\n')
        )}
      </td>
      <td className={'representatives-table-cell'}>
        <div className={'representatives-table-cell-actions disabled'}>
          <Button onClick={() => handleChangeRepresentative(representativeDetails)} type={'primary'} size={'small'}>
            {i18n.getMessage(`representativesTab.table.action.change`)}
          </Button>
          <Button onClick={() => handleRemoveRepresentative(representativeDetails)} type={'primary'} size={'small'}>
            {i18n.getMessage(`representativesTab.table.action.delete`)}
          </Button>
        </div>
      </td>
    </tr>
  );
};

RepresentativesTableContentRow.propTypes = {
  isLoading: PropTypes.bool,
  representativeDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    representative_account: PropTypes.shape({
      account_number: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired
    }).isRequired
  }),
  handleChangeRepresentative: PropTypes.func,
  handleRemoveRepresentative: PropTypes.func
};
