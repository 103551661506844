import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import './CustomersHeadRow.scss';

export const CUSTOMERS_TABLE_HEADER = [
  {
    label: 'customerId',
    sortBy: 'account_number'
  },
  {
    label: 'name',
    sortBy: 'name'
  },
  {
    label: 'onboardingStatus',
    sortBy: 'onboardingStatus'
  },
  {
    label: 'customerType',
    sortBy: 'type'
  },
  {
    label: 'kycAndCustomerStatuses'
  },
  {
    label: 'customerStatus'
  },
  {
    label: 'lastLoginAndRegistrationDate'
  },
  {
    label: 'externalKycLink'
  },
  {
    label: 'verificationStatuses'
  },
  {
    label: 'tags'
  },
  {
    label: 'source'
  },
  {
    label: 'customerOwner'
  }
];

export const CustomersHeadRow = ({ isShowActiveCustomers, handleOnSortTable, sortBy, direction }) => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {CUSTOMERS_TABLE_HEADER.map((head) => {
        const shouldDisplayColumn =
          (isShowActiveCustomers &&
            head.label !== 'lastLoginAndRegistrationDate' &&
            head.label !== 'customerStatus' &&
            head.label !== 'externalKycLink' &&
            head.label !== 'verificationStatuses' &&
            head.label !== 'tags') ||
          (!isShowActiveCustomers &&
            head.label !== 'kycAndCustomerStatuses' &&
            head.label !== 'source' &&
            head.label !== 'customerOwner');

        return shouldDisplayColumn ? (
          <th key={head.label} className={'customers-table-head'}>
            <div className={'customers-table-head-block'}>
              <span>{i18n.getMessage(`customers.table.header.${head.label}`)}</span>
              {head.sortBy && (
                <ArrowSelect
                  onClick={() => handleOnSortTable(head.sortBy)}
                  className={`arrow-icon ${sortBy === head.sortBy && direction}`}
                />
              )}
            </div>
          </th>
        ) : null;
      })}
    </tr>
  );
};

CustomersHeadRow.propTypes = {
  isShowActiveCustomers: PropTypes.bool,
  handleOnSortTable: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};
