import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import { AccountsHeadRow } from './components/AccountsHeadRow';
import { AccountsTableRow } from './components/AccountsTableRow';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import './AccountsTable.scss';

const AccountsTable = ({ accountsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination) => {
    const queryParams = {
      page: pagination.page,
      size: pagination.size,
      sort_direction: pagination.direction
    };

    if (filters.searchText) {
      queryParams.searchText = filters.searchText;
    }

    if (pagination.sortBy) {
      queryParams.sort_column = pagination.sortBy;
    }

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    accountsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!accountsStore.isInitialized) {
      accountsStore.getAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsStore.isInitialized]);

  useEffect(() => {
    if (accountsStore.isInitialized) {
      accountsStore.resetAccountsStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(accountsStore.filters, accountsStore.pagination);
    if (accountsStore.isInitialized) {
      accountsStore.getAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountsStore.filters.searchText,
    accountsStore.pagination.sortBy,
    accountsStore.pagination.direction,
    accountsStore.pagination.size,
    accountsStore.pagination.page
  ]);

  const handleSetCurrentPage = (pageNumber) => {
    accountsStore.setAccountsPage(pageNumber);
  };

  const handleSetPageSize = (size) => {
    accountsStore.setAccountsPageSize(size);
  };

  if (!accountsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  const handleSearchText = ({ target: { name, value } }) => {
    accountsStore.setFilter(name, value);
  };

  const handleOnSortTable = (newSortBy) => {
    accountsStore.setSortData({
      sortBy: newSortBy,
      direction:
        accountsStore.pagination.sortBy !== newSortBy
          ? DIRECTION.ASC
          : accountsStore.pagination.direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  return (
    <Container className={'accounts-container'} header={i18n.getMessage('container.accounts')}>
      <div className={'accounts-actions-wrapper'}>
        <Input
          className={'filters-wrapper'}
          name={'searchText'}
          label={i18n.getMessage('accounts.filter.search.label')}
          placeholder={i18n.getMessage('accounts.filter.search.placeholder')}
          value={accountsStore.filters.searchText}
          onChange={handleSearchText}
          Icon={SearchIcon}
        />

        <Button
          className={'accounts-button'}
          size={'medium'}
          type={'primary'}
          onClick={accountsStore.handleExportAccounts}
          isDisabled={!accountsStore.isInitialized || accountsStore.isLoadingAccountsCsv}
        >
          {accountsStore.isLoadingAccountsCsv ? <Loader /> : i18n.getMessage('accounts.button.accountsCsv')}
        </Button>
      </div>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={accountsStore.pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={accountsStore.isLoading}
        currentPage={accountsStore.pagination.page}
        numberOfPages={accountsStore.pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
      />

      <Table responsive>
        <thead>
          <AccountsHeadRow
            handleOnSortTable={handleOnSortTable}
            sortBy={accountsStore.pagination.sortBy}
            direction={accountsStore.pagination.direction}
          />
        </thead>
        <tbody>
          {accountsStore.accounts?.map((account, index) => (
            <AccountsTableRow key={index} type={'list'} isLoading={accountsStore.isLoading} accountData={account} />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={accountsStore.pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={accountsStore.isLoading}
        currentPage={accountsStore.pagination.page}
        numberOfPages={accountsStore.pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
        menuDirection={MENU_DIRECTION.UP}
      />
    </Container>
  );
};

AccountsTable.propTypes = {
  accountsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountsStore: stores.accountsStore
}))(observer(AccountsTable));
