import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import CustomerDetailsTab from './components/CustomerDetailsTab';
import CustomerDetailsModel from './components/CustomerDetailsModel';
import i18nContext from 'components/i18n-context';
import { CUSTOM_TABS_DIRECTION, CUSTOMER_TYPE } from 'components/constants';
import CustomTabs from 'components/CustomTabs';
import Loader from 'components/Loader';
import AccountsTab from 'components/CustomerDetailsTabs/AccountsTab';
import AmlNotesTab from 'components/CustomerDetailsTabs/AmlNotesTab';
import CardDetails from 'components/CustomerDetailsTabs/CardDetails';
import ProvidersTab from 'components/CustomerDetailsTabs/ProvidersTab';
import LimitsDetails from 'components/CustomerDetailsTabs/LimitsDetails';
import TariffsTab from 'components/CustomerDetailsTabs/TariffsTab';
import RepresentativesTab from 'components/CustomerDetailsTabs/RepresentativesTab';
import NotesTab from 'components/NotesTab';
import TransactionNotes from 'components/CustomerDetailsTabs/TransactionNotes';
import UserDetailsTable from 'components/UserDetailsTable';
import LogsTab from 'components/CustomerDetailsTabs/LogsTab';
import AmlTab from 'components/CustomerDetailsTabs/AmlTab';
import MonitoringTab from 'components/CustomerDetailsTabs/MonitoringTab';
import DocumentsTab from 'components/CustomerDetailsTabs/DocumentsTab';
import CustomerStatementTab from 'components/CustomerDetailsTabs/CustomerStatementTab';
import { isObjectEmpty } from 'services/utils';
import { Container } from 'uikit/Container/Container';
import './IndividualCustomerDetails.scss';

const INDIVIDUAL_CUSTOMER_DETAILS_TABS = {
  CUSTOMER_DETAILS: 'customerDetails',
  ACCOUNTS: 'accounts',
  CARDS: 'cards',
  TRANSACTIONS: 'transactions',
  MESSAGES: 'messages',
  CUSTOMER_NOTES: 'customerNotes',
  AML_NOTES: 'amlNotes',
  PROVIDERS: 'providers',
  TARIFFS: 'tariffs',
  LIMITS: 'limits',
  CUSTOMER_STATEMENT: 'customerStatement',
  PAYMENT_OBLIGATIONS: 'payment_obligations',
  USER: 'user',
  REPRESENTATIVE: 'representative',
  AML_FORM: 'amlForm',
  DOCUMENTS: 'documents',
  TRANSACTION_NOTES: 'transactionNotes',
  TRANSACTION_MONITORING: 'transactionMonitoring',
  LOGS: 'logs'
};

const REDIRECT_TABS = {
  TRANSACTIONS: 'transactions',
  MESSAGES: 'messages',
  PAYMENT_OBLIGATIONS: 'payment-obligations'
};

const IndividualCustomerDetails = ({ accountsStore, customersStore, transactionsStore }) => {
  const i18n = useContext(i18nContext);
  const searchParams = useLocation().search;
  const navigate = useNavigate();
  const { id: customerId } = useParams();
  const [isInitialized, setIsInitialized] = useState(false);
  const [activeTab, setActiveTab] = useState(INDIVIDUAL_CUSTOMER_DETAILS_TABS.CUSTOMER_DETAILS);

  useEffect(() => {
    if (searchParams) {
      const activeTabInSearchParams = queryString.parse(searchParams);

      if (activeTabInSearchParams.tab) {
        setActiveTab(activeTabInSearchParams.tab);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetCustomerDetails = async () => {
    await customersStore.getCustomerDetailsById(customerId, CUSTOMER_TYPE.INDIVIDUAL);
  };

  useEffect(() => {
    if (customerId) {
      customersStore.resetCustomersStorePagination();
      handleGetCustomerDetails().then(() => {
        setIsInitialized(true);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    if (activeTab) {
      if (REDIRECT_TABS[activeTab.toUpperCase()]) {
        const redirectUrl = `/${REDIRECT_TABS[activeTab.toUpperCase()]}?search_text=${customerId}`;

        navigate(redirectUrl);
      } else {
        const currentParams = new URLSearchParams(location.search);

        currentParams.delete('tab');

        const otherParamsString = currentParams.toString();

        const searchParams =
          otherParamsString &&
          (activeTab === INDIVIDUAL_CUSTOMER_DETAILS_TABS.TRANSACTION_NOTES ||
            activeTab === INDIVIDUAL_CUSTOMER_DETAILS_TABS.LOGS)
            ? `tab=${activeTab}&${otherParamsString}`
            : `tab=${activeTab}`;

        navigate({ search: `?${searchParams}` }, { replace: true });
        customersStore.resetCustomersStorePagination();
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleUpdateCustomerDetails = (data) =>
    customersStore.handleUpdateCustomerDetails(customerId, data, CUSTOMER_TYPE.INDIVIDUAL);
  const handleChangeCustomerStatus = (status) => customersStore.handleChangeCustomerStatus(customerId, status);
  const handleChangeOnboardingStatus = (status) => customersStore.handleChangeOnboardingStatus(customerId, status);
  const handleChangeKycStatus = (status) => customersStore.handleChangeKycStatus(customerId, status);
  const handleChangeExternalKycReference = (externalKycReference) =>
    customersStore.handleChangeExternalKycReference(customerId, externalKycReference);
  const handleChangeRiskProfile = (status) => customersStore.handleChangeRiskProfile(customerId, status);
  const handleChangeMlroApproval = (status) => customersStore.handleChangeMlroApproval(customerId, status);
  const handleChangeDirectorApproval = (status) => customersStore.handleChangeDirectorApproval(customerId, status);
  const handleChangeNextAMLCheckDate = (date) => customersStore.handleChangeNextAMLCheckDate(customerId, date);
  const handleReceiveKycData = () => customersStore.handleReceiveKycData(customerId);
  const handleChangeCustomerEmail = (email) => customersStore.handleChangeCustomerEmail(customerId, email);
  const getCustomerProviders = () => customersStore.getCurrentCustomerProviders(customerId);
  const handleAddNewPaymentProvider = (paymentProvider) =>
    customersStore.addNewPaymentProvider(customerId, paymentProvider);
  const handleGetLinkedRuleEngineProvider = () => customersStore.getLinkedRuleEngineProvider(customerId);
  const handleLinkRuleEngineProvider = (ruleEngine) => customersStore.linkRuleEngineProvider(customerId, ruleEngine);
  const handleUnlinkRuleEngineProvider = () => customersStore.unlinkRuleEngineProvider(customerId);
  const disableAccount = (accountNumber) => accountsStore.handleDisableAccount(accountNumber);
  const enableAccount = (accountNumber) => accountsStore.handleEnableAccount(accountNumber);
  const createNewAccount = (transferProvider, data) =>
    accountsStore.handleCreateNewAccount(customerId, transferProvider, data);
  const handleSetAccountPrimary = (accountNumber) => accountsStore.setAccountPrimary(accountNumber);
  const getCurrentCustomerCards = () => customersStore.getCurrentCustomerCards(customerId);
  const blockCustomerCard = (cardId, cardBlockType) =>
    customersStore.blockCustomerCard(customerId, cardId, cardBlockType);
  const unblockCustomerCard = (cardId) => customersStore.unblockCustomerCard(customerId, cardId);
  const replaceCustomerCard = (cardId) => customersStore.replaceCustomerCard(customerId, cardId);
  const updateCardsPerCustomerLimit = (cardsLimit) =>
    customersStore.updateCardsPerCustomerLimit(customerId, cardsLimit);
  const updateCardsPerAccountLimit = (cardsLimit) => customersStore.updateCardsPerAccountLimit(customerId, cardsLimit);
  const handleGetTariffGroupsList = () => customersStore.getTariffGroupsList();
  const handleGetTariffsGroup = (groupId) => customersStore.getTariffsGroup(groupId);
  const handleChangeCustomerTariffGroup = (groupId) => customersStore.changeCustomerTariffGroup(customerId, groupId);
  const handleApplyTariff = (tariffType) => customersStore.applyTariff(customerId, tariffType);
  const handleAddRepresentative = (email, permissions) =>
    customersStore.addRepresentative(customerId, email, permissions);
  const handleRemoveRepresentative = (representativeId) =>
    customersStore.removeRepresentative(customerId, representativeId);
  const handleUpdateRepresentativePermissions = (representativeId, permissions) =>
    customersStore.updateRepresentativePermissions(customerId, representativeId, permissions);
  const handleLoadCustomerNotes = () => customersStore.loadCustomerNotes(customerId);
  const handleLoadCustomerAmlNotes = () => customersStore.loadCustomerAmlNotes(customerId);
  const handleLoadCustomerTransactionNotes = () => customersStore.loadCustomerTransactionNotes(customerId);
  const handleAddCustomerNote = (note, assigneeId, documents) =>
    customersStore.addNote(customerId, note, assigneeId, documents);
  const handleAddAmlNote = (note, assigneeId, documents) =>
    customersStore.addAmlNote(customerId, note, assigneeId, documents);
  const handleDeleteNote = (noteId) => customersStore.deleteNote(noteId);
  const handleDownloadNoteDocument = (documentId) => customersStore.downloadNoteDocument(documentId);
  const handleSetPageNumber = (page) => customersStore.setPageNumber(page);
  const handleSetPageSize = (size) => customersStore.setPageSize(size);
  const handleSetFiltersFromUrl = (params) => customersStore.setFiltersFromUrl(params);
  const handleLoadCustomerLogs = () => customersStore.loadCustomerLogs(customerId);
  const handleLoadCustomerMonitoringDocuments = () => customersStore.loadCustomerMonitoringDocuments(customerId);
  const handleDownloadCustomerMonitoringDocumentById = (documentId) =>
    customersStore.downloadCustomerMonitoringDocumentById(documentId);
  const handleGetCustomerAml = () => customersStore.getCustomerAml(customerId, CUSTOMER_TYPE.INDIVIDUAL);
  const handleSaveAmlForm = (aml) => customersStore.saveAmlForm(customerId, aml);
  const handleLoadCustomerPdfReport = (from, to) => customersStore.loadCustomerPdfReport(customerId, from, to);
  const handleLoadCustomerCsvReport = (from, to) => customersStore.loadCustomerCsvReport(customerId, from, to);
  const handleGetCustomerDocuments = () => customersStore.getCustomerDocuments(customerId);
  const handleUploadCustomerDocument = (data) => customersStore.uploadCustomerDocument(customerId, data);
  const handleUpdateCustomerDocument = (documentId, data) =>
    customersStore.updateCustomerDocument(customerId, documentId, data);
  const handleDeleteCustomerDocument = (documentId) => customersStore.deleteCustomerDocument(customerId, documentId);
  const handleDownloadCustomerDocumentById = (documentId) =>
    customersStore.downloadCustomerDocumentById(customerId, documentId);

  const getTabContent = () => {
    switch (activeTab) {
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.CUSTOMER_DETAILS: {
        if (isObjectEmpty(customersStore.currentCustomerDetails)) {
          return <Loader className={'application-loader'} />;
        } else {
          return (
            <CustomerDetailsTab
              customerDetails={customersStore.currentCustomerDetails}
              userData={CustomerDetailsModel(customersStore.currentCustomerDetails)}
              handleUpdateCustomerDetails={handleUpdateCustomerDetails}
              // applicationConstants={this.props.applicationConstants}
              // fieldsErrors={customersStore.fieldsErrors}
              onChangeCustomerStatus={handleChangeCustomerStatus}
              onChangeOnboardingStatus={handleChangeOnboardingStatus}
              onReceiveKycData={handleReceiveKycData}
              onChangeExternalKycReference={handleChangeExternalKycReference}
              onSaveKycStatus={handleChangeKycStatus}
              onSaveNextAMLCheckDate={handleChangeNextAMLCheckDate}
              onSaveMlroApproval={handleChangeMlroApproval}
              onSaveDirectorApproval={handleChangeDirectorApproval}
              onSaveRiskProfile={handleChangeRiskProfile}
              onSaveEmail={handleChangeCustomerEmail}
            />
          );
        }
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.ACCOUNTS: {
        return (
          <AccountsTab
            isLoading={accountsStore.isLoading}
            accounts={customersStore.currentCustomerDetails?.wallets}
            customerNumber={customerId}
            updateCustomerDetails={handleGetCustomerDetails}
            disableAccount={disableAccount}
            enableAccount={enableAccount}
            addNewAccount={createNewAccount}
            setAccountPrimary={handleSetAccountPrimary}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.CARDS: {
        return (
          <CardDetails
            isLoading={customersStore.isCardActionsLoading}
            cards={customersStore.currentCustomerCards}
            cardsPerCustomerLimit={customersStore.currentCustomerDetails.cards_per_customer_limit}
            cardsPerAccountLimit={customersStore.currentCustomerDetails.cards_per_account_limit}
            getCurrentCustomerCards={getCurrentCustomerCards}
            blockCustomerCard={blockCustomerCard}
            unblockCustomerCard={unblockCustomerCard}
            replaceCustomerCard={replaceCustomerCard}
            updateCardsPerCustomerLimit={updateCardsPerCustomerLimit}
            updateCardsPerAccountLimit={updateCardsPerAccountLimit}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.CUSTOMER_NOTES: {
        return (
          <NotesTab
            isLoading={customersStore.isLoadingNotes}
            notes={customersStore.currentCustomerNotes}
            transactionNumber={''}
            handleLoadNotes={handleLoadCustomerNotes}
            handleAddNote={handleAddCustomerNote}
            handleDeleteNote={handleDeleteNote}
            handleDownloadDocument={handleDownloadNoteDocument}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.AML_NOTES: {
        return (
          <AmlNotesTab
            isLoading={customersStore.isLoadingNotes}
            amlNotes={customersStore.currentCustomerAmlNotes}
            handleLoadAmlNotes={handleLoadCustomerAmlNotes}
            handleAddAmlNote={handleAddAmlNote}
            handleDeleteAmlNote={handleDeleteNote}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.PROVIDERS: {
        return (
          <ProvidersTab
            isLoading={customersStore.isLoading}
            providers={customersStore.currentCustomerProviders}
            linkedRuleEngineProvider={customersStore.currentCustomerLinkedRuleEngineProvider}
            getCustomerProviders={getCustomerProviders}
            addNewProvider={handleAddNewPaymentProvider}
            getLinkedRuleEngineProvider={handleGetLinkedRuleEngineProvider}
            linkRuleEngineProvider={handleLinkRuleEngineProvider}
            unlinkRuleEngineProvider={handleUnlinkRuleEngineProvider}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.TARIFFS: {
        return (
          <TariffsTab
            isLoading={customersStore.isLoading}
            tariffs={customersStore.tariffs}
            customerTariffsGroup={customersStore.currentCustomerDetails.tariff_group}
            selectedTariffsGroup={customersStore.selectedTariffsGroup}
            getTariffGroupsList={handleGetTariffGroupsList}
            getTariffGroup={handleGetTariffsGroup}
            changeCustomerTariffGroup={handleChangeCustomerTariffGroup}
            applyTariff={handleApplyTariff}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.LIMITS: {
        return <LimitsDetails />;
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.CUSTOMER_STATEMENT: {
        return (
          <CustomerStatementTab
            loadCustomerStatementPdfReport={handleLoadCustomerPdfReport}
            loadCustomerStatementCsvReport={handleLoadCustomerCsvReport}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.USER: {
        return (
          <UserDetailsTable
            isUserDeletionAvailable={false}
            customerNumber={customerId}
            isInitialized={isInitialized}
            userInfo={customersStore.currentCustomerDetails.user}
            isCustomerDetails={true}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.REPRESENTATIVE: {
        return (
          <RepresentativesTab
            representatives={customersStore.currentCustomerDetails.representatives}
            handleAddRepresentative={handleAddRepresentative}
            handleRemoveRepresentative={handleRemoveRepresentative}
            handleUpdateRepresentativePermissions={handleUpdateRepresentativePermissions}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.AML_FORM: {
        return (
          <AmlTab
            isLoading={customersStore.isLoadingNotes}
            loadAmlData={handleGetCustomerAml}
            saveAmlData={handleSaveAmlForm}
            amlData={customersStore.currentCustomerAml}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.DOCUMENTS: {
        return (
          <DocumentsTab
            isLoading={customersStore.isLoading}
            documents={customersStore.currentCustomerDocuments}
            getDocuments={handleGetCustomerDocuments}
            uploadDocument={handleUploadCustomerDocument}
            updateDocument={handleUpdateCustomerDocument}
            deleteDocument={handleDeleteCustomerDocument}
            downloadDocument={handleDownloadCustomerDocumentById}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.TRANSACTION_NOTES: {
        return (
          <TransactionNotes
            isLoading={customersStore.isLoadingNotes}
            transactionNotes={customersStore.currentCustomerTransactionsNotes}
            handleLoadTransactionNotes={handleLoadCustomerTransactionNotes}
            pagination={customersStore.pagination}
            setPage={handleSetPageNumber}
            setSize={handleSetPageSize}
            setFiltersFromUrl={handleSetFiltersFromUrl}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.TRANSACTION_MONITORING: {
        return (
          <MonitoringTab
            isLoading={transactionsStore.isLoading}
            documents={customersStore.currentCustomerMonitoringDocuments}
            loadDocuments={handleLoadCustomerMonitoringDocuments}
            downloadDocument={handleDownloadCustomerMonitoringDocumentById}
          />
        );
      }
      case INDIVIDUAL_CUSTOMER_DETAILS_TABS.LOGS: {
        return (
          <LogsTab
            isLoading={customersStore.isLoadingNotes}
            logs={customersStore.currentCustomerLogs}
            firstAccountCreatedAt={customersStore.firstAccountCreatedAt}
            handleLoadLogs={handleLoadCustomerLogs}
            pagination={customersStore.pagination}
            setPage={handleSetPageNumber}
            setSize={handleSetPageSize}
            setFiltersFromUrl={handleSetFiltersFromUrl}
          />
        );
      }
      default:
        if (isObjectEmpty(customersStore.currentCustomerDetails)) {
          return <Loader className={'application-loader'} />;
        } else {
          return (
            <CustomerDetailsTab
              customerDetails={customersStore.currentCustomerDetails}
              userData={CustomerDetailsModel(customersStore.currentCustomerDetails)}
              handleUpdateCustomerDetails={handleUpdateCustomerDetails}
              // applicationConstants={this.props.applicationConstants}
              // fieldsErrors={customersStore.fieldsErrors}
              onChangeCustomerStatus={handleChangeCustomerStatus}
              onChangeOnboardingStatus={handleChangeOnboardingStatus}
              onReceiveKycData={handleReceiveKycData}
              onChangeExternalKycReference={handleChangeExternalKycReference}
              onSaveKycStatus={handleChangeKycStatus}
              onSaveNextAMLCheckDate={handleChangeNextAMLCheckDate}
              onSaveMlroApproval={handleChangeMlroApproval}
              onSaveDirectorApproval={handleChangeDirectorApproval}
              onSaveRiskProfile={handleChangeRiskProfile}
              onSaveEmail={handleChangeCustomerEmail}
            />
          );
        }
    }
  };

  if (!isInitialized) {
    return <Loader className={'application-loader individual-customer-details-loader'} />;
  }

  return (
    <Container
      className={'customer-details-container'}
      header={i18n.getMessage('container.individualCustomerDetails', {
        name: customersStore.currentCustomerDetails.name,
        surname: customersStore.currentCustomerDetails.surname
      })}
    >
      <div className={'customer-details-wrapper'}>
        <CustomTabs
          tabList={Object.keys(INDIVIDUAL_CUSTOMER_DETAILS_TABS).map((key) => ({
            name: INDIVIDUAL_CUSTOMER_DETAILS_TABS[key],
            title: i18n.getMessage(`customers.individual.details.tab.${INDIVIDUAL_CUSTOMER_DETAILS_TABS[key]}`)
          }))}
          direction={CUSTOM_TABS_DIRECTION.VERTICAL}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        {getTabContent()}
      </div>
    </Container>
  );
};

IndividualCustomerDetails.propTypes = {
  accountsStore: MobXPropTypes.observableObject,
  customersStore: MobXPropTypes.observableObject,
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountsStore: stores.accountsStore,
  customersStore: stores.customersStore,
  transactionsStore: stores.transactionsStore
}))(observer(IndividualCustomerDetails));
