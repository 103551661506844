import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import CustomersFiltersBar from './components/CustomersFiltersBar';
import { CustomersHeadRow } from './components/CustomersHeadRow';
import { CustomersTableRow } from './components/CustomersTableRow';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { ROUTE_PATHS } from 'routes/constants';
import { Container } from 'uikit/Container/Container';
import './CustomersTable.scss';

const CustomersTable = ({ customersStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = customersStore.prepareFiltersParams('customers');
    const queryParams = {
      ...filtersParams,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    customersStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!customersStore.isInitialized) {
      customersStore.requestInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersStore.isInitialized]);

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.ACTIVE_CUSTOMERS) {
      customersStore.setIsShowActiveCustomers(true);
    } else {
      customersStore.setIsShowActiveCustomers(false);
    }

    if (customersStore.isInitialized) {
      customersStore.resetCustomersStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(customersStore.filters, customersStore.pagination, customersStore.sortColumn);
    if (customersStore.isInitialized) {
      customersStore.getCustomersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customersStore.filters.from_date,
    customersStore.filters.to_date,
    customersStore.filters.account_statuses,
    customersStore.filters.kyc_statuses,
    customersStore.filters.risk_profiles,
    customersStore.filters.types,
    customersStore.filters.search_text,
    customersStore.filters.onboarding_statuses,
    customersStore.filters.customer_owners,
    customersStore.pagination.size,
    customersStore.pagination.page,
    customersStore.sortColumn.sort_column,
    customersStore.sortColumn.sort_direction
  ]);

  const handleSetCurrentPage = (pageNumber) => {
    customersStore.setPageNumber(pageNumber);
  };

  const handleSetPageSize = (size) => {
    customersStore.setPageSize(size);
  };

  const handleSetFilters = (name, value) => {
    customersStore.setFilter(name, value);
  };

  const handleSearchText = ({ target: { name, value } }) => {
    customersStore.setFilter(name, value);
  };

  const handleOnSortTable = (newSortBy) => {
    customersStore.setSortData({
      sortBy: newSortBy,
      direction:
        customersStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : customersStore.sortColumn.sort_direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  const handleCreateUserButtonClick = () => {
    navigate(ROUTE_PATHS.USER_CREATE);
  };

  if (!customersStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container
      className={'users-container'}
      header={i18n.getMessage(
        `${location.pathname === ROUTE_PATHS.ACTIVE_CUSTOMERS ? 'container.activeCustomers' : 'container.customers'}`
      )}
    >
      <CustomersFiltersBar
        isInitialized={customersStore.isInitialized}
        isShowActiveCustomers={customersStore.isShowActiveCustomers}
        isLoadingCustomerCsv={customersStore.isLoadingCustomerCsv}
        isLoadingBalancesCsv={customersStore.isLoadingBalancesCsv}
        filters={customersStore.filters}
        admins={customersStore.admins}
        handleExportCustomers={customersStore.handleExportCustomers}
        handleExportBalances={customersStore.handleExportBalances}
        handleCreateUserButtonClick={handleCreateUserButtonClick}
        handleSetFilters={handleSetFilters}
        handleSearchText={handleSearchText}
      />

      <Pagination
        isShowPaginationSize={true}
        paginationSize={customersStore.pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={customersStore.isLoading}
        currentPage={customersStore.pagination.page}
        numberOfPages={customersStore.pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
      />

      <Table responsive>
        <thead>
          <CustomersHeadRow
            isShowActiveCustomers={customersStore.isShowActiveCustomers}
            handleOnSortTable={handleOnSortTable}
            sortBy={customersStore.sortColumn.sort_column}
            direction={customersStore.sortColumn.sort_direction}
          />
        </thead>
        <tbody>
          {customersStore.customers?.map((customer, index) => (
            <CustomersTableRow
              key={index}
              type={'list'}
              isLoading={customersStore.isLoading}
              isShowActiveCustomers={customersStore.isShowActiveCustomers}
              customerData={customer}
            />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={customersStore.pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={customersStore.isLoading}
        currentPage={customersStore.pagination.page}
        numberOfPages={customersStore.pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
        menuDirection={MENU_DIRECTION.UP}
      />
    </Container>
  );
};

CustomersTable.propTypes = {
  customersStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customersStore: stores.customersStore
}))(observer(CustomersTable));
