import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { DIRECTION, USER_STATUS } from 'components/constants';
import i18n from 'i18n';
import {
  activateCustomerRequest,
  administrateCustomerRequest,
  blockCustomerRequest,
  createUserRequest,
  deleteUserRequest,
  exportUsersList,
  getUserInfoById,
  getUsersListRequest,
  updateUserPhoneRequest
} from 'services/requestAgent';

class UsersStore {
  isInitialized = false;
  isLoading = false;
  isLoadingUsersCsv = false;
  error = null;
  userInfo = null;
  users = null;
  pagination = {
    sortBy: 'user_id',
    page: 0,
    size: 20,
    totalPages: 0,
    totalElements: 0,
    direction: DIRECTION.ASC
  };
  filters = {
    userStatuses: Object.keys(USER_STATUS),
    searchText: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetUsersStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isLoadingUsersCsv = false;
    this.error = null;
    this.userInfo = null;
    this.users = null;
    this.pagination = {
      sortBy: 'user_id',
      page: 0,
      size: 20,
      totalPages: 0,
      totalElements: 0,
      direction: DIRECTION.ASC
    };
    this.filters = {
      userStatuses: Object.keys(USER_STATUS),
      searchText: ''
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsLoadingUsersCsv = (status) => {
    this.isLoadingUsersCsv = status;
    this.error = null;
  };

  setUsersPage = (page) => {
    this.pagination.page = page;
  };

  setUsersPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.pagination.sortBy = sortData.sortBy;
    this.pagination.direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      searchText: 'searchText',
      userStatuses: 'userStatuses'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        filters[filterParamsMapping[param]] = Array.isArray(params[param]) ? params[param] : [params[param]];
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    pagination.sortBy = params.sort_column || pagination.sortBy;
    pagination.direction = params.sort_direction || pagination.direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  getUsersList = async () => {
    this.setIsLoading(true);
    try {
      const {
        content: usersList,
        number: page,
        size,
        total_elements: totalElements,
        total_pages: totalPages
      } = await getUsersListRequest(
        this.pagination.sortBy,
        this.pagination.page,
        this.pagination.size,
        this.pagination.direction,
        this.filters.searchText,
        this.filters.userStatuses
      );

      runInAction(() => {
        this.isInitialized = true;
        this.users = usersList;
        this.pagination = {
          ...this.pagination,
          page,
          size,
          totalElements,
          totalPages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getUserInfoById = async (userId) => {
    this.setIsLoading(true);
    try {
      const userInfo = await getUserInfoById(userId);

      runInAction(() => {
        this.userInfo = userInfo;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  createUser = async (newUserData) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(createUserRequest(newUserData), {
      pending: i18n.getMessage('usersStore.createUser.toastPromise.pending'),
      success: i18n.getMessage('usersStore.createUser.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  deleteUser = async (userId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(deleteUserRequest(userId), {
      pending: i18n.getMessage('usersStore.deleteUser.toastPromise.pending'),
      success: i18n.getMessage('usersStore.deleteUser.toastPromise.success')
    });

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  updateUserPhone = async (userId, phone) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(updateUserPhoneRequest(userId, phone), {
      pending: i18n.getMessage('usersStore.updateUserPhone.toastPromise.pending'),
      success: i18n.getMessage('usersStore.updateUserPhone.toastPromise.success')
    });

    try {
      const userInfo = await toastPromise;
      runInAction(() => {
        this.userInfo = userInfo;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  activateUser = async (userId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(activateCustomerRequest(userId), {
      pending: i18n.getMessage('usersStore.activateUser.toastPromise.pending'),
      success: i18n.getMessage('usersStore.activateUser.toastPromise.success')
    });

    try {
      const userInfo = await toastPromise;
      runInAction(() => {
        this.userInfo = userInfo;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  blockUser = async (userId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(blockCustomerRequest(userId), {
      pending: i18n.getMessage('usersStore.blockUser.toastPromise.pending'),
      success: i18n.getMessage('usersStore.blockUser.toastPromise.success')
    });

    try {
      const userInfo = await toastPromise;
      runInAction(() => {
        this.userInfo = userInfo;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  administrateUser = async (userId) => {
    this.setIsLoading(true);

    const toastPromise = toast.promise(administrateCustomerRequest(userId), {
      pending: i18n.getMessage('usersStore.administrateUser.toastPromise.pending'),
      success: i18n.getMessage('usersStore.administrateUser.toastPromise.success')
    });

    try {
      const userInfo = await toastPromise;
      runInAction(() => {
        this.userInfo = userInfo;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleExportUsers = async () => {
    this.setIsLoadingUsersCsv(true);

    const toastPromise = toast.promise(
      exportUsersList({
        search_text: this.filters.searchText,
        user_statuses: this.filters.userStatuses
      }),
      {
        pending: i18n.getMessage('usersStore.handleExportUsers.toastPromise.pending'),
        success: i18n.getMessage('usersStore.handleExportUsers.toastPromise.success')
      }
    );

    try {
      await toastPromise;
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingUsersCsv(false);
    }
  };
}

export default new UsersStore();
