import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import TransitTransactionsFiltersBar from './components/TransitTransactionsFiltersBar';
import TransitTransactionsTableHead from './components/TransitTransactionsTableHead';
import TransitTransactionsTableRow from './components/TransitTransactionsTableRow';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { ROUTE_PATHS } from 'routes/constants';
import { Container } from 'uikit/Container/Container';
import './TransitTransactionsTable.scss';

const TransitTransactionsTable = ({ transitTransactionsStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = transitTransactionsStore.prepareFiltersParams();
    const queryParams = {
      ...filtersParams,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    transitTransactionsStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!transitTransactionsStore.isInitialized) {
      transitTransactionsStore.getTransitTransactionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitTransactionsStore.isInitialized]);

  useEffect(() => {
    if (transitTransactionsStore.isInitialized) {
      transitTransactionsStore.resetTransitTransactionStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(
      transitTransactionsStore.filters,
      transitTransactionsStore.pagination,
      transitTransactionsStore.sortColumn
    );
    if (transitTransactionsStore.isInitialized) {
      transitTransactionsStore.getTransitTransactionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transitTransactionsStore.filters.search_text,
    transitTransactionsStore.filters.from,
    transitTransactionsStore.filters.to,
    transitTransactionsStore.filters.transfer_providers,
    transitTransactionsStore.filters.statuses,
    transitTransactionsStore.filters.currencies,
    transitTransactionsStore.filters.direction,
    transitTransactionsStore.pagination.size,
    transitTransactionsStore.pagination.page,
    transitTransactionsStore.sortColumn.sort_column,
    transitTransactionsStore.sortColumn.sort_direction
  ]);

  const handleSearchText = ({ target: { name, value } }) => {
    transitTransactionsStore.setFilter(name, value);
  };

  const handleOnSortTable = (newSortBy) => {
    transitTransactionsStore.setSortData({
      sortBy: newSortBy,
      direction:
        transitTransactionsStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : transitTransactionsStore.sortColumn.sort_direction === DIRECTION.ASC
            ? DIRECTION.DESC
            : DIRECTION.ASC
    });
  };

  const handleCreatePaymentTransitTransaction = (transitTransactionId) => {
    navigate(`${ROUTE_PATHS.CREATE_TRANSACTION}/${transitTransactionId}`);
  };

  if (!transitTransactionsStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'transit-transactions-container'} header={i18n.getMessage('container.transitTransactions')}>
      <TransitTransactionsFiltersBar
        isLoading={transitTransactionsStore.isLoading}
        isInitialized={transitTransactionsStore.isInitialized}
        periodDate={transitTransactionsStore.periodDate}
        filters={transitTransactionsStore.filters}
        handleSetPeriodDate={transitTransactionsStore.setPeriodDate}
        handleSetFilters={transitTransactionsStore.setFilter}
        handleSearchText={handleSearchText}
        handleExportPeriodTransitTransactionList={transitTransactionsStore.loadPeriodTransitTransactionCsvReport}
        handleExportTransitTransactionList={transitTransactionsStore.loadTransitTransactionsCsvReport}
      />

      <Pagination
        isShowPaginationSize={true}
        paginationSize={transitTransactionsStore.pagination.size}
        handleChangePaginationSize={transitTransactionsStore.setPageSize}
        isLoading={transitTransactionsStore.isLoading}
        currentPage={transitTransactionsStore.pagination.page}
        numberOfPages={transitTransactionsStore.pagination.totalPages}
        setCurrentPage={transitTransactionsStore.setPageNumber}
      />

      <Table responsive>
        <thead>
          <TransitTransactionsTableHead
            handleOnSortTable={handleOnSortTable}
            sortBy={transitTransactionsStore.sortColumn.sort_column}
            direction={transitTransactionsStore.sortColumn.sort_direction}
          />
        </thead>
        <tbody>
          {transitTransactionsStore.transitTransactionList?.map((transitTransaction, index) => (
            <TransitTransactionsTableRow
              key={index}
              type={'list'}
              isLoading={transitTransactionsStore.isLoading}
              transitTransactionData={transitTransaction}
              handleRepeatTransitTransaction={transitTransactionsStore.repeatTransitTransaction}
              handleCreatePaymentTransitTransaction={handleCreatePaymentTransitTransaction}
              handleReturnTransitTransaction={transitTransactionsStore.returnTransitTransaction}
              handleDeleteTransitTransaction={transitTransactionsStore.deleteTransitTransaction}
            />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={transitTransactionsStore.pagination.size}
        handleChangePaginationSize={transitTransactionsStore.setPageSize}
        isLoading={transitTransactionsStore.isLoading}
        currentPage={transitTransactionsStore.pagination.page}
        numberOfPages={transitTransactionsStore.pagination.totalPages}
        setCurrentPage={transitTransactionsStore.setPageNumber}
        menuDirection={MENU_DIRECTION.UP}
      />
    </Container>
  );
};

TransitTransactionsTable.propTypes = {
  transitTransactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  transitTransactionsStore: stores.transitTransactionsStore
}))(observer(TransitTransactionsTable));
