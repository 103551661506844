export const UNAUTHORIZED_ROUTE_PATHS = {
  LOGIN: '/login'
};

export const ROUTE_PATHS = {
  ROOT: '/',
  CUSTOMERS: '/customers',
  CUSTOMER_CORPORATE: '/customers/corporate/:id',
  CUSTOMER_INDIVIDUAL: '/customers/individual/:id',
  ACTIVE_CUSTOMERS: '/active-customers',
  ACTIVE_CUSTOMER_CORPORATE: '/active-customers/corporate/:id',
  ACTIVE_CUSTOMER_INDIVIDUAL: '/active-customers/individual/:id',
  USERS: '/users',
  USER_DETAILS: '/users/:id',
  USER_CREATE: '/users/create',
  ACCOUNTS: '/accounts',
  SYSTEM_ACCOUNTS: '/system-accounts',
  TRANSACTIONS: '/transactions',
  TRANSACTION_DETAILS: '/transactions/:id',
  TRANSIT_TRANSACTIONS: '/transit-transactions',
  CREATE_TRANSACTION: '/new-transaction',
  LIMITS: '/limits',
  MESSAGES: '/messages',
  NEW_MESSAGE: '/messages/new',
  TOPIC_MESSAGES: '/messages/:id',
  TARIFFS: '/tariffs',
  PAYMENT_OBLIGATIONS: '/payment-obligations',
  CARD_AUTHORIZATIONS: '/card-authorizations',
  ...UNAUTHORIZED_ROUTE_PATHS
};

export const INITIAL_PATH_AFTER_LOGIN = ROUTE_PATHS.ROOT;
